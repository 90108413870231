<template>
    <div class="modal-backdrop">
        <section id="home" class="video-hero js-fullheight" v-bind:style="{ 'background-image': 'url(https://admin.itsyourdayofficial.com/foto_cover/'+client.foto_cover+')'}" style="background-size:cover; background-position: top center;" >
            <div class="overlay"></div>
            <!-- <a class="player" data-property="{videoURL:'https://www.youtube.com/watch?v=Mjjw19B7rMk',containment:'#home', showControls:false, autoPlay:true, loop:true, mute:true, startAt:0, opacity:1, quality:'default',optimizeDisplay:true}"></a> -->
            <div class="container">
                <div class="row js-fullheight justify-content-center d-flex align-items-center">
                    <div class="col-md-12">
                        <div class="text text-center">
                            <div class="icon">
                                <!-- <span class="flaticon-rose-outline-variant-with-vines-and-leaves"></span> -->
                                <span >
                                    <img class="wayang" src="assets/images/wayang.png" alt="icon">
                                </span>
                            </div>
                            <span class="subheading">The Wedding of</span>
                            <h1  v-if="client.hajat == 'pria'" >{{client.nama_pria}} &amp; {{client.nama_wanita}}</h1>
                            <h1  v-if="client.hajat == 'wanita'" >{{client.nama_wanita}} &amp; {{client.nama_pria}}</h1>
                            <p style="color: white; font-weight: bold; ">
                                {{ config.language === 1 ? "To Mr./Mrs./Sir/Madam" : 'Kepada Bapak/Ibu/Saudara/i' }}
                            </p>
                            <h4 style="color: white; font-weight: 400; font-family: 'Dancing Script', cursive; font-size:7vw;">
                                {{guest.nama}}
                            </h4>
                            <h4 v-if="showName" style="color: white; font-weight: 400; font-family: 'Dancing Script', cursive; font-size:7vw;">
                                {{name}}
                            </h4>
                            <p style="color: white; font-weight: 600;" class="honor">
                                {{ config.language === 1 ? "Without reducing respect, we invite you to attend our wedding" : 'Tanpa mengurangi rasa hormat, Kami mengundang Anda untuk hadir diacara pernikahan Kami' }}
                            </p>
                            <div class="wrap">
                                <button class="button" @click="close">{{ config.language === 1 ? "Open Invitation" : 'Buka Undangan' }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    
</template>

<script>
  export default {
    name: 'Modal',
      props: ['client', 'guest', 'config'],
      methods: {
        close() {
          this.$emit('close');
        },
    },
    computed: {
        name() {
            let toParam = this.$route.query.to;
            if (toParam) {
                toParam = decodeURIComponent(toParam);
                if (toParam.includes('-')) {
                return '';
                } else {
                return toParam;
                }
            } else {
                return '';
            }
        },
        showName() {
            let toParam = this.$route.query.to;
            if (toParam) {
                toParam = decodeURIComponent(toParam);
                return !toParam.includes('-');
            } else {
                return false;
            }
        },
    },
  };
</script>