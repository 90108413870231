<template>
    <div class="home">
        <section id="home" class="video-hero js-fullheight" v-bind:style="{ 'background-image': 'url(assets/images/header-opening-bottom.png), url(https://admin.itsyourdayofficial.com/foto_cover/'+client.foto_cover+'), linear-gradient(rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.8) 100%), url(/images/header.jpg)', 'background-size': '100% 100px, cover, cover', 'background-repeat': 'no-repeat', 'background-position': 'center calc(100% + 4rem), center center, center center'}" >
        <!-- <section id="home" class="video-hero js-fullheight" v-bind:style="{ 'background-image': 'url(https://admin.itsyourdayofficial.com/foto_cover/'+client.foto_cover+')'}" style="height: 700px; background-size:cover; background-position: top center;" > -->
	  	    <div class="overlay"></div>
			<!-- <a class="player" data-property="{videoURL:'https://www.youtube.com/watch?v=Mjjw19B7rMk',containment:'#home', showControls:false, autoPlay:true, loop:true, mute:true, startAt:0, opacity:1, quality:'default',optimizeDisplay:true}"></a> -->
			<div class="container">
				<div class="row js-fullheight justify-content-center d-flex align-items-center">
					<div class="col-md-12">
						<div class="text text-center">
							<div class="icon">
								<!-- <span class="flaticon-rose-outline-variant-with-vines-and-leaves"></span> -->
                                <span >
                                    <img class="wayang" src="assets/images/wayang.png" alt="icon">
                                </span>
							</div>
							<span class="subheading">The Wedding of</span>
							<h1  v-if="client.hajat == 'pria'" >{{client.nama_pria}} &amp; {{client.nama_wanita}}</h1>
                            <h1  v-if="client.hajat == 'wanita'" >{{client.nama_wanita}} &amp; {{client.nama_pria}}</h1>
							<div id="timer" class="d-flex">
                                <div class="time" id="days">{{days}} <span>{{ config.language === 1 ? 'Days' : 'Hari' }}</span></div>
                                <div class="time pl-3" id="hours">{{hours}} <span>{{ config.language === 1 ? 'Hours' : 'Jam' }}</span></div>
                                <div class="time pl-3" id="minutes">{{minutes}} <span>{{ config.language === 1 ? 'Minutes' : 'minutes' }}</span></div>
                                <div class="time pl-3" id="seconds">{{seconds}} <span>{{ config.language === 1 ? 'Seconds' : 'Detik' }}</span></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="ftco-section bg-section" id="married-section">
			<div class="overlay-top" style="background-image: url(assets/images/top-border.jpg);"></div>
			<div class="overlay-bottom" style="background-image: url(assets/images/bot-border.jpg);"></div>
			<div class="container" style="padding-top: 50px; padding-bottom: 80px;">
				<div class="row justify-content-center pb-5">
                <div class="col-md-12 text-center heading-section ftco-animate">
                    <span class="clone">{{ config.language === 1 ? "The Bride's" : 'Mempelai' }}</span>
                    <h2 v-if="client.hajat == 'pria'" class="mb-3">{{client.nama_pria}}  &amp; {{client.nama_wanita}}</h2>
                    <h2 v-if="client.hajat == 'wanita'" class="mb-3">{{client.nama_wanita}}  &amp; {{client.nama_pria}}</h2>
                </div>
			</div>
			<div class="row justify-content-center">
				<div class="col-md-10">
					<div class="row">
						<div class="col-md-6 my-5 text-center d-flex align-items-stretch">
							<div class="bride-groom ftco-animate">
								<div v-if="config.profile_picture !== 1" class="img" v-bind:style="[client.hajat == 'pria' ? { 'background-image': 'url(https://admin.itsyourdayofficial.com/foto_calon/'+client.foto_pria+')'} : { 'background-image': 'url(https://admin.itsyourdayofficial.com/foto_calon/'+client.foto_wanita+')'}]"></div>
                                
								<div class="text mt-4 px-4">
									<h2 v-if="client.hajat == 'pria'">{{client.gelar_pria}}</h2>
                                    <h2 v-if="client.hajat == 'wanita'">{{client.gelar_wanita}}</h2>
								</div>
							</div>
						</div>
						<div class="col-md-6 my-5 text-center d-flex align-items-stretch">
							<div class="bride-groom ftco-animate">
                                <div v-if="config.profile_picture !== 1" class="img" v-bind:style="[client.hajat == 'wanita' ? { 'background-image': 'url(https://admin.itsyourdayofficial.com/foto_calon/'+client.foto_pria+')'} : { 'background-image': 'url(https://admin.itsyourdayofficial.com/foto_calon/'+client.foto_wanita+')'}]"></div>
								<div class="text mt-4 px-4">
                                    <h2 v-if="client.hajat == 'wanita'">{{client.gelar_pria}}</h2>
									<h2 v-if="client.hajat == 'pria'">{{client.gelar_wanita}}</h2>
								</div>
							</div>
						</div>
					</div>
				  </div>
			</div>
			</div>
		</section>

        <section class="ftco-section ftco-about ftco-no-pt ftco-no-pb" id="groom-bride-section">
			<div class="container">
				<div class="row my-3">
					<div class="col-md-12">
						<div class="wrap">
							<div class="row d-flex mx-0">
								<div class="col-md-6 d-flex">
									<div class="img d-flex align-self-stretch align-items-center" v-bind:style="{ 'background-image': 'url(https://admin.itsyourdayofficial.com/gallery/'+img1.file+')'}">
									</div>
								</div>
								<div class="col-md-6 py-md-5 text">
									<div class="py-md-4 px-3">
										<div class="row justify-content-start pb-3">
											<div class="col-md-12 ftco-animate p-4 p-lg-5 text-center">
												<span class="subheading mb-4">
                                                    {{ config.language === 1 ? "With gratitude and asking for Your blessing, we intend to hold a wedding reception for our sons and daughters:" : 'Dengan rasa syukur dan memohon Ridho-Mu, kami bermaksud menyelenggarakan resepsi pernikahan putra - putri kami :' }}
                                                </span>
												<div v-if="client.hajat == 'wanita'" >
                                                    <h2 class="mt-4">
                                                        <!-- {{client.nama_wanita}} <br><span>&amp;</span><br> {{client.nama_pria}} -->
                                                        {{client.nama_wanita}}
                                                    </h2>
                                                    <div class="row ">
                                                        <div class="col-md-12 col-sm-12 text-center">
                                                            <span >{{client.nama_ayah_wanita}} <hr class="my-0"> {{client.nama_ibu_wanita}}</span>                                                                
                                                        </div>
                                                    </div>
                                                    <h2 class="mt-2"><span>&amp;</span></h2>
                                                    <h2 class="">
                                                        {{client.nama_pria}}
                                                    </h2>
                                                    <div class="row ">
                                                        <div class="col-md-12 col-sm-12 text-center">
                                                            <span >{{client.nama_ayah_pria}} <hr class="my-0"> {{client.nama_ibu_pria}}</span>                                                                
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-if="client.hajat == 'pria'" >
                                                    <h2 class="mt-4">
                                                        {{client.nama_pria}}
                                                    </h2>
                                                    <div class="row ">
                                                        <div class="col-md-12 col-sm-12 text-center">
                                                            <span >{{client.nama_ayah_pria}} <hr class="my-0"> {{client.nama_ibu_pria}}</span>                                                                
                                                        </div>
                                                    </div>
                                                    <h2 class="mt-2"><span>&amp;</span></h2>
                                                    <h2 class="">
                                                        {{client.nama_wanita}}
                                                    </h2>
                                                    <div class="row ">
                                                        <div class="col-md-12 col-sm-12 text-center">
                                                            <span >{{client.nama_ayah_wanita}} <hr class="my-0"> {{client.nama_ibu_wanita}}</span>                                                                
                                                        </div>
                                                    </div>
                                                </div>
                                                <span class="icon flaticon-rose-variant-outline-with-vines"></span>
											</div>
										</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>
		</section>
        
		<section class="ftco-section " id="when-where-section" style="background-image: url('assets/images/header-opening-top.png'), linear-gradient(#fdf0e1 0%, #fdf0e1 100%); background-size: 100% 100px, cover; background-repeat: no-repeat; background-position: center calc(0% - 1rem), center center; padding-bottom: 2em;">
			<div class="container">
				<div class="row justify-content-center pb-3">
					<div class="col-md-12 text-center heading-section ftco-animate">
						<span class="clone">{{ config.language === 1 ? "The Wedding Event" : 'Acara Pernikahan' }}</span>
						<h2 class="mb-3">{{ config.language === 1 ? "Place & Time" : 'Tempat & Waktu' }}</h2>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 ftco-animate">
						<div class="place img" style="background-image: url(assets/images/place-2.jpg);">
							<div class="text text-center">
								<span class="icon flaticon-wedding-kiss"></span>
								<h5 style="border-bottom: 1px solid #CE9E5F;">Akad</h5>
                                <h4>{{ client.nama_venue }}</h4>
								<p>
                                    <span>{{ config.language === 1 ? moment(client.tgl_akad).locale("en").format("dddd, DD MMMM YYYY") : moment(client.tgl_akad).locale("id").format("dddd, DD MMMM YYYY") }}</span>
                                    <br>
                                    <span v-if="client.tgl_akad_selesai == null" style="color: #f67e7d;">{{ config.language === 1 ? moment(client.tgl_akad).format("HH:mm") + ' - End' : moment(client.tgl_akad).format("HH:mm") + ' - Selesai' }}</span>
                                    <span v-else style="color: #f67e7d;">{{ moment(client.tgl_akad).format("HH:mm") }} - {{ moment(client.tgl_akad_selesai).format("HH:mm") }}</span>
                                </p>
								<p v-html="client.alamat_venue"></p>
								<!-- <p><a href="#">021 000 000</a></p> -->
								<p v-if="client.url_maps_resepsi !== null">
                                    <a :href="client.url_maps" target="_blank" class="btn-custom">See Map</a>
                                </p>
                                <p v-else-if="config.maps_section !== 1">
                                    <a :href="client.url_maps" target="_blank" class="btn-custom">See Map</a>
                                </p>
                                <p v-else-if="client.url_maps_resepsi == null && config.maps_section === 1">
                                    <!-- <a :href="client.url_maps" target="_blank" class="btn-custom">See Map</a> -->
                                </p>
							</div>
						</div>
					</div>
					<div class="col-md-6 ftco-animate">
						<div class="place img" style="background-image: url(assets/images/place-3.jpg);">
							<div class="text text-center">
								<span class="icon flaticon-cake"></span>
								<h5 style="border-bottom: 1px solid #CE9E5F;">{{ config.language === 1 ? 'Reception' : 'Resepsi' }}</h5>
                                <h4>{{ client.nama_venue_resepsi }}</h4>
								<p>
                                    <span>{{ config.language === 1 ? moment(client.tgl_resepsi).locale("en").format("dddd, DD MMMM YYYY") : moment(client.tgl_resepsi).locale("id").format("dddd, DD MMMM YYYY") }}</span>
                                    <br>
                                    <span v-if="client.tgl_resepsi_selesai == null" style="color: #f67e7d;">{{ config.language === 1 ? moment(client.tgl_resepsi).format("HH:mm") + ' - End' : moment(client.tgl_resepsi).format("HH:mm") + ' - Selesai' }}</span>
                                    <span v-else style="color: #f67e7d;">{{ moment(client.tgl_resepsi).format("HH:mm") }} - {{ moment(client.tgl_resepsi_selesai).format("HH:mm") }}</span>
                                </p>
								<p v-html="client.alamat_venue_resepsi"></p>
								<!-- <p><a href="#">021 000 000</a></p> -->
								<p v-if="client.url_maps_resepsi !== null">
                                    <a :href="client.url_maps_resepsi" target="_blank" class="btn-custom">See Map</a>
                                </p>
                                <p v-else-if="config.maps_section !== 1">
                                    <a :href="client.url_maps" target="_blank" class="btn-custom">See Map</a>
                                </p>
                                <p v-else-if="client.url_maps_resepsi == null && config.maps_section === 1">
                                    <!-- <a :href="client.url_maps" target="_blank" class="btn-custom">See Map</a> -->
                                </p>
							</div>
						</div>
					</div>					
				</div>				
			</div>
		</section>
        <section v-if="config.maps_section !== 0" class="ftco-section " id="when-where-section" style="background-image: url('assets/images/header-opening-bottom.png'), linear-gradient(#fdf0e1 0%, #fdf0e1 100%); background-size: 100% 100px, cover; background-repeat: no-repeat; background-position: center calc(100% + 2rem), center center; ">
        <!-- <section v-if="config.maps_section !== 0" class="ftco-section bg-light" id="when-where-section"> -->
			<div class="container">
				<div class="row justify-content-center pb-5">
					<div class="col-md-12 text-center heading-section ftco-animate">
						<span class="clone">{{ config.language === 1 ? 'Navigation' : 'Navigasi' }}</span>
						<h2 class="mb-3">{{ config.language === 1 ? 'Map Location' : 'Peta Lokasi' }}</h2>
					</div>
				</div>
				<div class="row">
					<iframe class="ftco-animate" :src="client.embed" width="95%" height="450" style="border:0; border-radius: 20px !important; margin: auto; padding-top: 30px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
				</div>	
                <br>
                <div class="pt-10">
					<div class="form-group text-center">
                        <a :href="client.url_maps" target="_blank" class="btn btn-primary py-3 px-4">{{ config.language === 1 ? 'Open Map' : 'Buka Maps' }}</a>
                    </div>
				</div>				
			</div>
		</section>

		<section class="ftco-section" id="gallery-section" >
			<div class="container-fluid px-md-4">
                <div class="row justify-content-center pb-5">
                    <div class="col-md-12 text-center heading-section ">
                        <span class="clone">{{ config.language === 1 ? 'Photos' : 'Foto' }}</span>
                        <h2 class="mb-3">{{ config.language === 1 ? 'Gallery' : 'Galeri' }}</h2>
                    </div>
                </div>
                <div class="row justify-content-center ">
                    <div class="col-md-3" v-for="item in gallery" :key="item.id">
                        <div class="text-center" style="margin-bottom: 20px;">
                            <img v-img:group :src="'https://admin.itsyourdayofficial.com/gallery/' + item.file"  class="gallery img d-flex align-items-center justify-content-center" alt="...">
                        </div>
                    </div>
                </div>
			</div>
		</section>

        <section v-if="config.story_section !== 0" class="ftco-section" id="lovestory-section" style="padding-top: 0em;">
			<div class="container">
				<div class="row justify-content-center pb-5">
					<div class="col-md-12 text-center heading-section ftco-animate">
						<span class="clone">{{ config.language === 1 ? 'Love Story' : 'Kisah Cinta' }}</span>
						<h2 class="mb-3">{{ config.language === 1 ? 'Our Story' : 'Kisah Kami' }}</h2>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<ul class="timeline animate-box">
									<li v-if="story.type1 !== undefined" class="animate-box">
										<div class="timeline-badge" style="background-image:url(assets/images/couple-1.jpg);"></div>
										<div class="timeline-panel ftco-animate text-md-right">
											<div class="overlay"></div>
                                            <img v-if="story.type1.type == 'foto'" :src="'https://admin.itsyourdayofficial.com/story/' + story.media1.media" width="100%" style="border-radius: 20px;" alt="">
                                            <video v-if="story.type1.type == 'video'" width="100%" height="280px;" :src="'https://admin.itsyourdayofficial.com/story/' + story.media1.media +'#t=0.001'" type="video/mp4" controls preload="metadata"></video>
											<div class="timeline-heading" style="margin-top: 20px;">
												<span class="date">{{story.month1.rangeTime}}</span>
												<h3 class="timeline-title">{{story.title1.title}}</h3>
											</div>
											
											<div class="timeline-body">
												<p v-html="story.description1.description"></p>
											</div>
										</div>
									</li>
									<li v-if="story.type2 !== undefined" class="timeline-inverted animate-box">
										<div class="timeline-badge" style="background-image:url(assets/images/couple-2.jpg);"></div>
										<div class="timeline-panel ftco-animate">
											<div class="overlay overlay-2"></div>
                                            <img v-if="story.type2.type == 'foto'" :src="'https://admin.itsyourdayofficial.com/story/' + story.media2.media" width="100%" style="border-radius: 20px;" alt="">
                                            <video v-if="story.type2.type == 'video'" width="100%" height="280px;" :src="'https://admin.itsyourdayofficial.com/story/' + story.media2.media +'#t=0.001'" type="video/mp4" controls preload="metadata"></video>
											<div class="timeline-heading" style="margin-top: 20px;">
												<span class="date">{{story.month2.rangeTime}}</span>
												<h3 class="timeline-title">{{story.title2.title}}</h3>
											</div>
											<div class="timeline-body">
												<p v-html="story.description2.description"></p>
											</div>
										</div>
									</li>
									<li v-if="story.type3 !== undefined" class="animate-box">
										<div class="timeline-badge" style="background-image:url(assets/images/couple-3.jpg);"></div>
										<div class="timeline-panel ftco-animate text-md-right">
											<div class="overlay"></div>
                                            <img v-if="story.type3.type == 'foto'" :src="'https://admin.itsyourdayofficial.com/story/' + story.media3.media" width="100%" style="border-radius: 20px;" alt="">
                                            <video v-if="story.type3.type == 'video'" width="100%" height="280px;" :src="'https://admin.itsyourdayofficial.com/story/' + story.media3.media +'#t=0.001'" type="video/mp4" controls preload="metadata"></video>
											<div class="timeline-heading" style="margin-top: 20px;">
												<span class="date">{{story.month3.rangeTime}}</span>
												<h3 class="timeline-title">{{story.title3.title}}</h3>
											</div>
											<div class="timeline-body">
												<p v-html="story.description3.description"></p>
											</div>
										</div>
									</li>
									<li v-if="story.type4 !== undefined" class="timeline-inverted animate-box">
										<div class="timeline-badge" style="background-image:url(assets/images/couple-4.jpg);"></div>
										<div class="timeline-panel ftco-animate">
											<div class="overlay overlay-2"></div>
                                            <img v-if="story.type4.type == 'foto'" :src="'https://admin.itsyourdayofficial.com/story/' + story.media4.media" width="100%" style="border-radius: 20px;" alt="">
                                            <video v-if="story.type4.type == 'video'" width="100%" height="280px;" :src="'https://admin.itsyourdayofficial.com/story/' + story.media4.media +'#t=0.001'" type="video/mp4" controls preload="metadata"></video>
											<div class="timeline-heading" style="margin-top: 20px;">
												<span class="date">{{story.month4.rangeTime}}</span>
												<h3 class="timeline-title">{{story.title4.title}}</h3>
											</div>
											<div class="timeline-body">
												<p v-html="story.description4.description"></p>
											</div>
										</div>
									</li>
                                    <li v-if="story.type5 !== undefined" class="animate-box">
										<div class="timeline-badge" style="background-image:url(assets/images/couple-3.jpg);"></div>
										<div class="timeline-panel ftco-animate text-md-right">
											<div class="overlay"></div>
                                            <img v-if="story.type5.type == 'foto'" :src="'https://admin.itsyourdayofficial.com/story/' + story.media5.media" width="100%" style="border-radius: 20px;" alt="">
                                            <video v-if="story.type5.type == 'video'" width="100%" height="280px;" :src="'https://admin.itsyourdayofficial.com/story/' + story.media5.media +'#t=0.001'" type="video/mp4" controls preload="metadata"></video>
											<div class="timeline-heading" style="margin-top: 20px;">
												<span class="date">{{story.month5.rangeTime}}</span>
												<h3 class="timeline-title">{{story.title5.title}}</h3>
											</div>
											<div class="timeline-body">
												<p v-html="story.description5.description"></p>
											</div>
										</div>
									</li>
							</ul>
					</div>
				</div>
   			</div>
   		</section>

		<section class="ftco-section bg-section" id="married-section">
			<div class="overlay-top" style="background-image: url(assets/images/border-top-batik.png);"></div>
			<div class="overlay-bottom" style="background-image: url(assets/images/border-bot-batik.png);"></div>
			<div class="container">
				<div class="row justify-content-center pt-5 pb-5">
			<div class="col-md-12 text-center heading-section  ">
				<span class="clone">{{ config.language === 1 ? 'Prayer Quotes' : "Kutipan Do'a" }}</span>
				<h2 class="mb-3">{{ config.language === 1 ? 'Prayer Quotes' : "Kutipan Do'a" }}</h2>
			</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-md-10">
					<div class="row">
						<div class="col-md-12 my-5 text-center d-flex align-items-stretch ">
							<div class="bride-groom">
								<!-- <div class="img" style="background-image: url(assets/images/personn.png);"></div> -->
								<div class="text mt-4 px-4">
									<p v-html="client.quote" style="font-family: Dancing Script, cursive;color: rgb(21, 21, 21);padding-top: 3rem;font-size: 25px;"></p>
									<h2 style="font-family: Dancing Script, cursive;color: #CE9E5F;">{{client.title_quote}}</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>
		</section>

		
		
		<section v-if="config.rsvp_by_wa_section !== 0" class="ftco-section " id="when-where-section" style="background-image: url('assets/images/header-opening-top.png'), linear-gradient(#fdf0e1 0%, #fdf0e1 100%); background-size: 100% 100px, cover; background-repeat: no-repeat; background-position: center calc(0% - 3rem), center center; padding-bottom: 0em;">
			<div class="container">
				<div class="row justify-content-center pb-5">
			<div class="col-md-12 text-center heading-section ">
				<span class="clone">{{ config.language === 1 ? 'Reservation' : 'Reservasi' }}</span>
				<h2 class="mb-3">{{ config.language === 1 ? 'Are You Attending?' : 'Apakah Anda akan Hadir?' }}</h2>
			</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-md-7">
					<form action="#" class="rsvp-form " style="border:2px solid #CE9E5F; ">
                        <div class="">
							<div class="form-group">
								<input type="text" v-model="name" class="form-control" :placeholder="config.language === 1 ? 'Please Fill Your Name' : 'Masukkan Nama Anda'">
							</div>								
						</div>
						<div class="">
							<div class="form-group">
								<input type="radio" id="one" value="Iya, Saya akan Hadir" v-model="picked" required/>
                                <label for="one" style="font-size: 14px;">&nbsp;{{ config.language === 1 ? 'Yes, I will attend' : 'Iya, Saya akan Hadir' }}</label>
							</div>
                            <div class="form-group">
                                <input type="radio" id="two" value="Maaf, Saya Tidak Bisa Hadir" v-model="picked" required/>
                                <label for="two" style="font-size: 14px;">&nbsp;{{ config.language === 1 ? 'Sorry, I cant attend' : 'Maaf, Saya Tidak Bisa Hadir' }}</label>
                            </div>
						</div>
                        <div class="">
							<div class="form-group text-center">
                                <a :href="'https://api.whatsapp.com/send?phone=' + client.wa_number + '&text= Hai, saya ' + name + ' ingin konfirmasi kehadiran undangan pernikahan bahwa ' + picked + '. Terima kasih'" target="_blank" type="submit" class="btn btn-primary py-3 px-4">RSVP Via Whatsapp</a>
                            </div>
						</div>                            
					</form>
				</div>
			</div>
			</div>
			
		</section>

        <section v-if="config.gift_section !== 0" class="ftco-section " style="background-image: url('assets/images/header-opening-bottom.png'), linear-gradient(#fdf0e1 0%, #fdf0e1 100%); background-size: 100% 100px, cover; background-repeat: no-repeat; background-position: center calc(100% + 2rem), center center; " id="rsvp-section">
			<div class="container">
				<div class="row justify-content-center pb-5">
                    <div class="col-md-12 text-center heading-section ">
                        <span class="clone">{{ config.language === 1 ? "Send a Gift" : 'Kirim Hadiah' }}</span>
                        <h2 class="mb-3">{{ config.language === 1 ? "Wedding Gift" : 'Hadiah Pernikahan' }}</h2>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-6  row justify-content-center ">
                        <div class="place img" style="background-image: url(assets/images/place-2.jpg);border-radius: 10px;">
                            <div class="text text-center">
                                <span class="icon flaticon-wedding-kiss"></span>
                                <h3>{{ config.language === 1 ? "Send a Gift" : 'Kirim Hadiah' }}</h3>
                                <p><span>
                                    {{ config.language === 1 ? "For those of you who wish to send wedding gifts, you can do so via:" : 'Bagi Bapak/Ibu/Saudara/i yang ingin mengirimkan hadiah pernikahan dapat melalui:' }}
                                </span></p>
                                <p>
                                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
                                        {{ config.language === 1 ? "Wedding Gift" : 'Hadiah Pernikahan' }}
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>            
                </div>
			</div>			
		</section>

		<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
                    <center><h5 class="modal-title" id="exampleModalLongTitle" style="font-family: Dancing Script, cursive;color: #CE9E5F;font-weight: 700;font-size: 25px !important;text-align: center;">{{ config.language === 1 ? "Send Gifts To The Bride" : 'Kirim Hadiah Untuk Mempelai' }}</h5></center>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
				</div>
				<div class="modal-body">
                    <div class="col-12" style="border:2px solid rgba(88, 100, 255, 0.1); margin-top: 10px;">
                        <div class="col-12 mx-auto mt-3 mb-3 text-center"> 
                            <p class="card-text" v-if="client.bank == 'BCA'"><img src="assets/images/bca.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                            <p class="card-text" v-if="client.bank == 'BRI'"><img src="assets/images/bri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                            <p class="card-text" v-if="client.bank == 'OVO'"><img src="assets/images/ovo.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                            <p class="card-text" v-if="client.bank == 'MANDIRI'"><img src="assets/images/mandiri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                        </div>
                        <div class="input-group col-12 mx-auto mb-3">
                            <input type="text" v-model="client.no_acc" class="form-control" id="myInput" readonly>
                            <div class="input-group-append">
                            <button data-target="#collapseExample"  class="btn btn-outline-secondary" type="button" @click.prevent="myFunction" id="button-addon2">Salin</button>
                            </div>
                        </div>
                    </div>
                    <div v-if="client.bank2 != null" class="col-12" style="border:2px solid rgba(88, 100, 255, 0.1); margin-top: 10px;">
                        <div class="col-12 mx-auto mt-3 mb-3 text-center"> 
                            <p class="card-text" v-if="client.bank2 == 'BCA'"><img src="assets/images/bca.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank2}}</p>
                            <p class="card-text" v-if="client.bank2 == 'BRI'"><img src="assets/images/bri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank2}}</p>
                            <p class="card-text" v-if="client.bank2 == 'OVO'"><img src="assets/images/ovo.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank2}}</p>
                            <p class="card-text" v-if="client.bank2 == 'MANDIRI'"><img src="assets/images/mandiri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                        </div>
                        <div class="input-group col-12 mx-auto mb-3">
                            <input type="text" v-model="client.no_acc2" class="form-control" id="myInput2" readonly>
                            <div class="input-group-append">
                            <button data-target="#collapseExample2" class="btn btn-outline-secondary" type="button" @click.prevent="myFunction2" id="button-addon2">Salin</button>
                            </div>
                        </div>
                    </div>
                    <div v-if="client.bank3 != null" class="col-12" style="border:2px solid rgba(88, 100, 255, 0.1); margin-top: 10px;">
                        <div class="col-12 mx-auto mt-3 mb-3 text-center"> 
                            <p class="card-text" v-if="client.bank3 == 'BCA'"><img src="assets/images/bca.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank3}}</p>
                            <p class="card-text" v-if="client.bank3 == 'BRI'"><img src="assets/images/bri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank3}}</p>
                            <p class="card-text" v-if="client.bank3 == 'OVO'"><img src="assets/images/ovo.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank3}}</p>
                            <p class="card-text" v-if="client.bank3 == 'MANDIRI'"><img src="assets/images/mandiri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                        </div>
                        <div class="input-group col-12 mx-auto mb-3">
                            <input type="text" v-model="client.no_acc3" class="form-control" id="myInput3" readonly>
                            <div class="input-group-append">
                            <button data-target="#collapseExample3" class="btn btn-outline-secondary" type="button" @click.prevent="myFunction3" id="button-addon2">Salin</button>
                            </div>
                        </div>
                    </div>
                    <div v-if="client.bank4 != null" class="col-12" style="border:2px solid rgba(88, 100, 255, 0.1); margin-top: 10px;">
                        <div class="col-12 mx-auto mt-3 mb-3 text-center"> 
                            <p class="card-text" v-if="client.bank4 == 'BCA'"><img src="assets/images/bca.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank4}}</p>
                            <p class="card-text" v-if="client.bank4 == 'BRI'"><img src="assets/images/bri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank4}}</p>
                            <p class="card-text" v-if="client.bank4 == 'OVO'"><img src="assets/images/ovo.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank4}}</p>
                            <p class="card-text" v-if="client.bank4 == 'MANDIRI'"><img src="assets/images/mandiri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                        </div>
                        <div class="input-group col-12 mx-auto mb-3">
                            <input type="text" v-model="client.no_acc4" class="form-control" id="myInput4" readonly>
                            <div class="input-group-append">
                            <button data-target="#collapseExample4" class="btn btn-outline-secondary" type="button" @click.prevent="myFunction4" id="button-addon2">Salin</button>
                            </div>
                        </div>
                    </div>
				</div>
				
			</div>
			</div>
		</div>
		

		<section class="ftco-section " id="married-section" >
			<div class="overlay-top" style="background-image: url(assets/images/top-bg.jpg);"></div>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-md-12 text-center heading-section ">
						<span class="clone">{{ config.language === 1 ? "Greetings" : 'Salam' }}</span>
						<h2 class="mb-3">{{ config.language === 1 ? "Wedding Wish" : 'Ucapan Pernikahan' }}</h2>
					</div>
				</div>
				<div class="row justify-content-center" >
					<div class="col-md-7" >
						<form action="#" class="rsvp-form "  style="border:2px solid #CE9E5F; border-radius: 20px;">
                            <div class="">
								<div class="form-group">
									<input type="text" v-model="submit.name" class="form-control" :placeholder="config.language === 1 ? 'Please Fill Your Name' : 'Masukkan Nama Anda'">
                                    <span v-if="errors.name" style="margin-bottom:10px; background:#e79552" class="badge badge-danger">
                                        {{ config.language === 1 ? "Please Fill Your Name" : 'Masukkan Nama Anda' }}
                                    </span>
								</div>								
							</div>
                            <div class="">
								<div class="form-group">
									<div class="form-field">
                                        <div class="select-wrap">
                                            <div class="icon d-flex align-items-center justify-content-center"><span class="ion-ios-arrow-down"></span></div>
                                            <select v-model="submit.confirmation" class="form-control" >
                                                <option value="" disabled selected>
                                                    {{ config.language === 1 ? "Confirmation of attendance" : 'Konfirmasi Kehadiran' }}
                                                </option>
                                                <option value="hadir">{{ config.language === 1 ? "Attend" : 'Hadir' }}</option>
                                                <option value="mungkin">{{ config.language === 1 ? "Maybe Attend" : 'Mungkin Hadir' }}</option>
                                                <option value="tidak">{{ config.language === 1 ? "Not Attend" : 'Tidak Hadir' }}</option>
                                            </select>
                                            <span v-if="errors.confirmation" style="margin-bottom:10px; background:#e79552" class="badge badge-danger">
                                                {{ config.language === 1 ? "Please fill your attendance confirmation" : 'Harap isi konfirmasi kehadiran anda' }}
                                            </span>
                                        </div>
						            </div>
								</div>
							</div>
							<div class="">
								<div class="form-group">
                                    <textarea v-model="submit.comment" name="" id="" cols="30" rows="2" class="form-control" :placeholder="config.language === 1 ? 'Write Your Message' : 'Tulis Pesan Anda'"></textarea>
                                </div>
							</div>
                            <div class="">
								<div class="form-group text-center">
                                    <button @click.prevent="post"  type="submit" class="btn btn-primary py-3 px-4">{{ config.language === 1 ? "Send Hopes" : 'Kirim Ucapan' }}</button>
                                </div>
							</div>
						</form>
					</div>
			
				</div>

				<div class="row justify-content-center widget " style="margin-top:10px;">
					<ul class="col-11 col-md-7 list-group">
						<li v-for="h in hopes" :key="h.id" class="list-group-item">
							<div class="row">
								<div class="col-4 col-md-2">
								  <img src="assets/images/wedding-rings-2.png" class="img-responsive" style="border:5px solid #CE9E5F; border-radius:50%; width:80px;" alt="" />
								</div>
								<div class="col-8 col-md-10" style="background:#CE9E5F; border-radius:10px;">
								  <div>
									<b style="font-size:18px;color:white;">{{h.name}}</b>
								  </div>
								  <div>
									<p v-if="h.confirmation == 'hadir'" style="font-size:14px;font-style: oblique;color:white;">{{ config.language === 1 ? "Attend" : 'Hadir' }}</p>
                                    <p v-if="h.confirmation == 'mungkin'" style="font-size:14px;font-style: oblique;color:white;">{{ config.language === 1 ? "Maybe Attend" : 'Mungkin Hadir' }}</p>
                                    <p v-if="h.confirmation == 'tidak'" style="font-size:14px;font-style: oblique;color:white;">{{ config.language === 1 ? "Not Attend" : 'Tidak Hadir' }}</p>
								  </div>
								  <div class="comment-text">
									<label for="one" style="font-size:16px; color:white;">"{{h.comment}}"</label>
								  </div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section>

		<section v-if="config.invitation_section !== 0" class="ftco-section" id="when-where-section" style="padding-top: 0em;">
			<div class="container">
				<div class="row justify-content-center pb-2 protokol-sec" >
					<div class="col-md-12 text-center heading-section ">						
                        <span class="clone">{{ config.language === 1 ? "Special Invitation" : 'Undangan Spesial' }}</span>
						<h2 class="mb-3">{{ config.language === 1 ? "Also Invite" : 'Turut Mengundang' }}</h2>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-md-10">
						<div class="row">
							<div class="col-md-12 my-5 text-center d-flex align-items-stretch">
								<div class="bride-groom ">
									<div class="row text mt-6 px-4">

										<div class="col-md-6 col-sm-6 text-center">
											<h2 style="font-family: Dancing Script, cursive;color: #CE9E5F;">{{ config.language === 1 ? "Bride's Family" : 'Keluarga Mempelai Wanita' }}</h2>
											<ul class="list-group">
                                                <span v-for="invite in alsoInvite" :key="invite.id">
                                                    <p v-if="invite.type === 'wanita'"><i class="lni lni-checkmark-circle"></i> {{ invite.invite }}</p>
                                                </span>
												
											</ul>
										</div>
										<div class="col-md-6 col-sm-6 text-center">
											<h2 style="font-family: Dancing Script, cursive;color: #CE9E5F;">{{ config.language === 1 ? "Groom's Family" : 'Keluarga Mempelai Pria' }}</h2>
											<ul class="list-group">
												<span v-for="invite in alsoInvite" :key="invite.id">
                                                    <p v-if="invite.type === 'pria'"><i class="lni lni-checkmark-circle"></i> {{ invite.invite }}</p>
                                                </span>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- <section class="ftco-section" id="when-where-section" style="background-image: url('assets/images/header-opening-top.png'), url('assets/images/header-opening-bottom.png'), linear-gradient(#fdf0e1 0%, #fdf0e1 100%); background-size: 100% 100px, 100% 100px, cover, cover; background-repeat: no-repeat; background-position: center calc(0% - 1rem), center calc(100% + 2rem), center center, center center; padding-bottom: 4em;">
			<div class="container">
				<div class="row justify-content-center pb-2 protokol-sec" >

					<div class="col-md-12 text-center heading-section ">
                        <span class="clone">{{ config.language === 1 ? "Prevent Covid 19" : 'Mencegah Covid 19' }}</span>
						<h2 class="mb-3">{{ config.language === 1 ? "Health Protocol" : 'Protokol Kesehatan' }}</h2>
					</div>
				</div>
				<div class="row justify-content-center text-center" >
					<div class="col-md-12">
                        <span class="subheading mb-4  text-center">{{ config.language === 1 ? "Without reducing respect, due to the current situation amidst the COVID-19 pandemic, we apologize because the event will be held in accordance with government regulations and recommendations." : 'Tanpa mengurangi rasa hormat, dikarenakan situasi yang sedang terjadi ditengah pandemi COVID-19 ini kami memohon maaf karena acara akan diselenggarakan sesuai peraturan dan imbauan pemerintah.' }}</span>
                    </div>
					<div class="col-md-12">
                        <img src="assets/images/covid-prokes.jpeg" class="protokol-img " alt="">
                    </div>
				</div>
			</div>
		</section> -->

		<!-- <section v-if="config.brides_groom_section !== 0" class="ftco-section" id="people-section" >
			<div class="container-fluid px-md-5">
				<div class="row justify-content-center pb-5">
			<div class="col-md-12 text-center heading-section ">
				<span class="clone">People</span>
				<h2 class="mb-3">Girls's &amp; Boy's Bridesmaid</h2>
			</div>
			</div>
			<div class="row">
				<div class="col-md-12">
                    <vue-horizontal ref="horizontal" class="horizontal" :button-between="false" @scroll-debounce="onScrollDebounce">
                        <div class="item text-center" v-for="(bride, index) in brides" :key="index"  >
                            <div class="content" v-bind:style="{ 'background-image': 'url(https://admin.itsyourdayofficial.com/foto_brides/'+ bride.photo +')'}"></div>

                            <div class="text">
                                <h2>{{bride.name}}</h2>
                                <h4>{{bride.instagram}}</h4>
                                <span v-if="bride.type === 'groom'">Groomsmen</span>
                                <span v-if="bride.type === 'bride'">Bridesmaid</span>
                            </div>
                        </div>
                    </vue-horizontal>
				</div>
			</div>
			</div>
		</section> -->

		<section class="ftco-section bg-end-section" id="married-section" style="padding: 0em;">
			
			<div class="container">
				<div class="row  text-center">
					<h2 class="my-3 mt-6 text-gretings " >{{ config.language === 1 ? "It is an honor and happiness for us as a family if you are willing to be present to give your blessing to the bride and groom. We thank you for your presence and blessings." : 'Merupakan suatu kehormatan dan kebahagiaan bagi kami sekeluarga apabila Bapak/Ibu/Saudara/i berkenan hadir untuk memberikan doa restu kepada kedua mempelai. Atas kehadiran serta doa restu, kami ucapkan terima kasih.' }} </h2>
                </div>
			</div>
		</section>

        <section class="ftco-section bg-end-section" id="married-section" style="padding-top: 1em;">
			<!-- <div class="overlay-top" style="background-image: url(assets/images/top-bg.jpg);"></div> -->
			<div class="overlay-bottom" style="background-image: url(assets/images/bot-border.jpg);"></div>
			<div class="container ">
				<div class="text-center justify-content-center end-section-bg">
                    <h1  v-if="client.hajat == 'pria'" style="font-family: 'Dancing Script', cursive; font-size: 9vw; color: #bb9f6d;">{{client.nama_pria}} &amp; {{client.nama_wanita}}</h1>
                    <h1  v-if="client.hajat == 'wanita'" style="font-family: 'Dancing Script', cursive; font-size: 9vw; color: #bb9f6d;">{{client.nama_wanita}} &amp; {{client.nama_pria}}</h1>
                    
                </div>
			</div>
		</section>

        <audio :src="'https://admin.itsyourdayofficial.com/song/'+ audio" ref="audioElm" loop="loop"></audio>
        <Modal
        v-show="isModalVisible"
        @close="closeModal"
        :client='client'
        :guest='guest'
        :config="config"
        />

        <div style="z-index: 1000;" class="icon-bar">
            <button class="play" @click="play" v-if="playing"><i class="lni lni-pause"></i></button>
            <button class="play" @click="play" v-else><i class="lni lni-play"></i></button>
        </div>
        <!-- loader -->
        <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px"><circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/><circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00"/></svg></div>
        
    </div>
</template>

<script>
    import moment from 'moment';
    import Modal from '../components/Modal.vue';
    import VueHorizontal from "vue-horizontal";
    export default {
        name: 'home',
        components: {
            Modal,
            VueHorizontal
        },
        data(){
            return{
                client: {},
                guest: {},
                moment: moment,
                now: 0,
                count: 0,
                date: '',
                gallery: {},
                alsoInvite: {},
                config: {},
                brides: {},
                story: {
                    title1: '',
                    description1: '',
                    date1: '',
                    month1: '',
                    year1: '',
                    media1: '',
                    type1: '',
                    order1: '',
                    title2: '',
                    description2: '',
                    date2: '',
                    month2: '',
                    year2: '',
                    media2: '',
                    type2: '',
                    order2: '',
                    title3: '',
                    description3: '',
                    date3: '',
                    month3: '',
                    year3: '',
                    media3: '',
                    type3: '',
                    order3: '',
                    title4: '',
                    description4: '',
                    date4: '',
                    month4: '',
                    year4: '',
                    media4: '',
                    type4: '',
                    order4: '',
                    title5: '',
                    description5: '',
                    date5: '',
                    month5: '',
                    year5: '',
                    media5: '',
                    type5: '',
                    order5: '',
                },
                form: {
                    address: '',
                    latitude : '',
                    longitude : '',
                    test: ''
                },
                submit : {
                            client_id : '',
                    name: '',
                    comment: '',
                    confirmation: '',
                        },
                isModalVisible: true,
                playing: true,
                audio : '',
                errors : {},
                hopes: {},
                name: '',
                picked: '',
                img1: '',
            }
        },
        mounted() {
            // this.timer_loop();

            let toParam = this.$route.query.to;
            if (toParam) {
            toParam = decodeURIComponent(toParam);
                if (toParam.includes('-')) {
                    // use original parameter value
                } else {
                    // use empty string as parameter value
                    toParam = '';
                }
            } else {
                toParam = '';
            }
            this.$api.get('/' + this.$route.params.slug, {
                params: {
                    to: toParam,
                    template: window.location.origin + "/"
                }
            })
            .then(response =>{
                if (!response.data.data) {
                    this.$router.push({ name: 'PageNotFound' });
                    return;
                }
                var currentUrl = window.location.href;
      
                this.client = response.data.data;
                document.title = "Wedding Invitation " + response.data.data.nama_wanita + " & " + response.data.data.nama_pria + " by It'sYourDay";
                // Mengganti ikon situs
                // const favicon = document.querySelector('link[rel="icon"]');
                // favicon.href = "https://admin.itsyourdayofficial.com/foto_cover/"+response.data.data.foto_cover; // Ubah value href dengan value yang berasal dari respons API
                if(currentUrl.includes(this.client.template) == false){
                    this.$router.push({ name: 'PageNotFound' });
                }

                this.guest = response.data.data;
                this.submit.client_id = this.client.id;
                this.date = moment(response.data.data.tgl_akad).format('MMMM DD, YYYY hh:mm:ss a');
                if (response.data.data.config[0].countdown == 1) {
                    this.date = moment(response.data.data.tgl_resepsi).format('MMMM DD, YYYY hh:mm:ss a');
                }else{
                    this.date = moment(response.data.data.tgl_akad).format('MMMM DD, YYYY hh:mm:ss a');
                }
                this.gallery = response.data.data.gallery;
                this.alsoInvite = response.data.data.also_invite;
                this.brides = response.data.data.brides;
                this.audio = response.data.data.song;
                this.story.title1 = response.data.data.story[0];
                this.story.description1 = response.data.data.story[0];
                this.story.date1 = response.data.data.story[0];
                this.story.month1 = response.data.data.story[0];
                this.story.year1 = response.data.data.story[0];
                this.story.media1 = response.data.data.story[0];
                this.story.type1 = response.data.data.story[0];
                this.story.order1 = response.data.data.story[0];
                this.story.title2 = response.data.data.story[1];
                this.story.description2 = response.data.data.story[1];
                this.story.date2 = response.data.data.story[1];
                this.story.month2 = response.data.data.story[1];
                this.story.year2 = response.data.data.story[1];
                this.story.media2 = response.data.data.story[1];
                this.story.type2 = response.data.data.story[1];
                this.story.order2 = response.data.data.story[1];
                this.story.title3 = response.data.data.story[2];
                this.story.description3 = response.data.data.story[2];
                this.story.date3 = response.data.data.story[2];
                this.story.month3 = response.data.data.story[2];
                this.story.year3 = response.data.data.story[2];
                this.story.media3 = response.data.data.story[2];
                this.story.type3 = response.data.data.story[2];
                this.story.order3 = response.data.data.story[2];
                this.story.title4 = response.data.data.story[3];
                this.story.description4 = response.data.data.story[3];
                this.story.date4 = response.data.data.story[3];
                this.story.month4 = response.data.data.story[3];
                this.story.year4 = response.data.data.story[3];
                this.story.media4 = response.data.data.story[3];
                this.story.type4 = response.data.data.story[3];
                this.story.order4 = response.data.data.story[3];
                this.story.title5 = response.data.data.story[4];
                this.story.description5 = response.data.data.story[4];
                this.story.date5 = response.data.data.story[4];
                this.story.month5 = response.data.data.story[4];
                this.story.year5 = response.data.data.story[4];
                this.story.media5 = response.data.data.story[4];
                this.story.type5 = response.data.data.story[4];
                this.story.order5 = response.data.data.story[4];
                this.config = response.data.data.config[0];
                this.timer_loop();
                this.modifiedDate = Math.trunc(Date.parse(this.date) / 1000);
                this.$api.get('comment/' + this.client.id)
                .then(response => { 
                    this.hopes = response.data.data;
                })
                this.img1 = response.data.data.gallery[4];
                
            })
            .catch(error => {
                // this.$router.push({ name: 'PageNotFound' });
                console.log(error.response.data);
            });

            AOS.init({
                duration: 800,
                easing: 'slide'
            });

            (function($) {

                var fullHeight = function() {

                    $('.js-fullheight').css('height', $(window).height());
                    $(window).resize(function(){
                        $('.js-fullheight').css('height', $(window).height());
                    });

                };
                fullHeight();

                var loader = function() {
                    setTimeout(function() { 
                        if($('#ftco-loader').length > 0) {
                            $('#ftco-loader').removeClass('show');
                        }
                    }, 1);
                };
                loader();


                var contentWayPoint = function() {
                    var i = 0;
                    $('.ftco-animate').waypoint( function( direction ) {

                        if( direction === 'down' && !$(this.element).hasClass('ftco-animated') ) {
                            
                            i++;

                            $(this.element).addClass('item-animate');
                            setTimeout(function(){

                                $('body .ftco-animate.item-animate').each(function(k){
                                    var el = $(this);
                                    setTimeout( function () {
                                        var effect = el.data('animate-effect');
                                        if ( effect === 'fadeIn') {
                                            el.addClass('fadeIn ftco-animated');
                                        } else if ( effect === 'fadeInLeft') {
                                            el.addClass('fadeInLeft ftco-animated');
                                        } else if ( effect === 'fadeInRight') {
                                            el.addClass('fadeInRight ftco-animated');
                                        } else {
                                            el.addClass('fadeInUp ftco-animated');
                                        }
                                        el.removeClass('item-animate');
                                    },  k * 50, 'easeInOutExpo' );
                                });
                                
                            }, 100);
                            
                        }

                    } , { offset: '95%' } );
                };
                contentWayPoint();
                
            })(jQuery);
        },
        created(){
            if(this.isModalVisible == true){
                document.body.style.overflow = "hidden"; 
                document.body.style.position= "fixed";
            }
            
        },
        methods:{
            timer_loop() {
                this.count++;
                this.now = Math.trunc(new Date().getTime() / 1000);
                if (this.count < 200 && this.modifiedDate > this.now) {
                    setTimeout(this.timer_loop, 1000);
                } else {
                    this.count = 0;
                }
            },

            play: function(event) {
                var a = this.$refs.audioElm;
                if (a.paused) {
                    a.play();
                    this.playing = true;
                } else {
                    a.pause();
                    this.playing = false;
                }
            },

            myFunction() {
                /* Get the text field */
                var copyText = document.getElementById("myInput");
                const button = document.body.querySelector('[data-target="#collapseExample"]')
                /* Select the text field */
                copyText.select();
                copyText.setSelectionRange(0, 99999); /* For mobile devices *
                /* Copy the text inside the text field */
                navigator.clipboard.writeText(copyText.value)
                if(button.innerText.toLowerCase() === 'salin') {
                    button.innerText = 'Disalin';
                }
            },

            myFunction2() {
                /* Get the text field */
                var copyText = document.getElementById("myInput2");
                const button = document.body.querySelector('[data-target="#collapseExample2"]')
                /* Select the text field */
                copyText.select();
                copyText.setSelectionRange(0, 99999); /* For mobile devices *
                /* Copy the text inside the text field */
                navigator.clipboard.writeText(copyText.value)
                if(button.innerText.toLowerCase() === 'salin') {
                    button.innerText = 'Disalin';
                }
            },

            myFunction3() {
                /* Get the text field */
                var copyText = document.getElementById("myInput3");
                const button = document.body.querySelector('[data-target="#collapseExample3"]')
                /* Select the text field */
                copyText.select();
                copyText.setSelectionRange(0, 99999); /* For mobile devices *
                /* Copy the text inside the text field */
                navigator.clipboard.writeText(copyText.value)
                if(button.innerText.toLowerCase() === 'salin') {
                    button.innerText = 'Disalin';
                }
            },

            myFunction4() {
                /* Get the text field */
                var copyText = document.getElementById("myInput4");
                const button = document.body.querySelector('[data-target="#collapseExample4"]')
                /* Select the text field */
                copyText.select();
                copyText.setSelectionRange(0, 99999); /* For mobile devices *
                /* Copy the text inside the text field */
                navigator.clipboard.writeText(copyText.value)
                if(button.innerText.toLowerCase() === 'salin') {
                    button.innerText = 'Disalin';
                }
            },

            closeModal() {
                this.isModalVisible = false;
                var a = this.$refs.audioElm;
                a.play();
                this.playing = true;

                if(this.isModalVisible == false){
                    document.body.style.position= null;
                    document.body.style.overflow = "auto"; 
                }
            },

            post(){
                this.$api.post('comment', this.submit)
                .then(response =>{
                    this.success = response.data.success
                    this.submit.name = '';
                    this.submit.comment = '';
                    this.submit.confirmation = '';
                    this.$api.get('comment/' + this.client.id)
                    .then(response => { 
                        this.hopes = response.data.data;
                    })
                    console.log(response)
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.errors = error.response.data.errors;
                    }
                });
            }
        },
        computed:{
            seconds() {
                const result = (this.modifiedDate - this.now) % 60;
                return result < 0 ? 0 : result;
            },
            minutes() {
                const result = Math.trunc((this.modifiedDate - this.now) / 60) % 60;
                return result < 0 ? 0 : result;
            },
            hours() {
                const result = Math.trunc((this.modifiedDate - this.now) / 60 / 60) % 24;
                return result < 0 ? 0 : result;
            },
            days() {
                const result = Math.trunc((this.modifiedDate - this.now) / 60 / 60 / 24);
                return result < 0 ? 0 : result;
            },
            modifiedDate : function(){
                return Math.trunc(Date.parse(this.date) / 1000)
            }
        },
        
 }
</script>

<style scoped>
.item {
  width: 240px;
  /* padding: 32px 32px 24px 32px;
  background: #f1f1f1; */
  margin-right: 20px;
}

.content {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  padding-top: 60%;
  height: 240px;
}

.title {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}
</style>

<!-- Pagination CSS -->
<style scoped>
.pagination {
  margin-top: 12px;
  display: flex;
  justify-content: center;
}

.dot {
  padding: 4px;
  cursor: pointer;
}

.dot > div {
  border-radius: 10px;
  width: 10px;
  height: 10px;
  background: #33333350;
}

.dot:hover > div {
  border: 1px solid black;
  background: white;
}

.dot.current > div {
  border: 3px solid black;
  background: white;
}
</style>

<!-- Parent CSS (Container) -->
<style scoped>
main, article {
  padding: 24px 0;
}
</style>
